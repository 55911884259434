import React, { useCallback, useEffect, useRef, useState } from "react"
import Layout from "../../components/shared-layout";
import { Alert, Autocomplete, Avatar, Box, InputBase, ListItem, ListItemText, FormControlLabel, CircularProgress, IconButton, InputAdornment, Radio, RadioGroup, TextField, Typography, Checkbox, Grid, Chip, Tooltip, setRef, Switch, Popover, Divider, Skeleton, ToggleButtonGroup, SelectChangeEvent, InputLabel, FormControl, Select, MenuItem, Paper } from "@mui/material";
import moment from "moment";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import PlaylistAddCircleIcon from '@mui/icons-material/PlaylistAddCircle';
import { ITEM_PERFECT_INLINED, PADDING_HORIZONTAL, THEME_YELLOW } from "../../constants/style";
import VmButton from "../../components/shared-button";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import useStores from "../../hooks/use-stores";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import TravelPlanningStepper from "../../components/travel-planning-stepper";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import HistoryIcon from '@mui/icons-material/History';
import RouteIcon from '@mui/icons-material/Route';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import Geocode from "react-geocode";
import { googleMapApiKey } from "../../constants/settings";
import { GoogleMap, useJsApiLoader, DirectionsRenderer } from '@react-google-maps/api';
import { DefaultRoutingObj, IsAttractionBookedInTPBooking, RemoveProductFromBookingListByDay } from "../../utilities/travel-planning";
import InititalTripStep from "../../components/travel-planning/initiate-trip-step";
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { isWeb } from "../../utilities/platform";
import { MAX_PLACES_PER_DAY, MAX_PLAN_DAYS_LIMIT } from "../../constants/options";
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import SouthIcon from '@mui/icons-material/South';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';
import MapIcon from '@mui/icons-material/Map';
import SlideDown from "react-slidedown";
import FlagIcon from '@mui/icons-material/Flag';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import { navigate } from "gatsby";

const TravelPlanningIndexPage = observer(() => {
  const { rootStore, bookingStore } = useStores();
  const { t }: any = useTranslation();
  const [step, setStep] = useState<"ChatGPT" | "Planning">("ChatGPT");
  const [savedPlaces, setSavedPlaces] = useState<any>();// Other day's places, update when day change
  const [isSamePlaceSelectable, setIsSamePlaceSelectable] = useState<any>(false);
  const [tripDayMode, setTripDayMode] = useState<"View" | "Edit">("Edit");
  const [currentTripDay, setCurrentTripDay] = useState<number>(0);// The day displaying
  const [revertConfirm, setRevertConfirm] = useState<boolean>(false);
  const [deleteConfirm, setDeleteConfirm] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [anchorDeleteEl, setAnchorDeleteEl] = useState<HTMLButtonElement | null>(null);
  const [openRoutePlanner, setOpenRoutePlanner] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<any>([]);
  const [travelMode, setTravelMode] = useState<google.maps.TravelMode | null>();
  const [startPoint, setStartPoint] = useState<string>("");
  const { isLoaded } = useJsApiLoader({ id: 'google-map-script', googleMapsApiKey: googleMapApiKey });
  const [dayTab, setDayTab] = useState<any>([]);
  const [showMoreTabs, setShowMoreTabs] = useState<boolean>(false);

  // Route Planning
  const [directionResponse, setDirectionResponse] = useState<any>(null);
  const [routeInfo, setRouteInfo] = useState<any>(null);
  const routeRef = useRef(null);
  const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

  useEffect(() => {
    if (isTripPlanningReady()) {
      let tabs = [];
      for (var i = 0; i < savedPlaces.day.length; i++)  tabs.push(i);
      setDayTab(tabs);
      onCaculateRouting();
    }
  }, [savedPlaces])

  const initialPlanning = (tripInfo?: any) => {
    Geocode.setApiKey(googleMapApiKey);
    setTravelMode(google.maps.TravelMode.DRIVING);
    setCurrentTripDay(0);
    let originalTrip = tripInfo ? tripInfo : isWeb && localStorage.TRIP_PLAN ? JSON.parse(localStorage.TRIP_PLAN) : {};
    let req: any = tripInfo ? tripInfo : originalTrip;
    setStartPoint(originalTrip.day[0][0] ? originalTrip.day[0][0].name : "");
    // uncomment if u need coords getting from geocode api
    // onDefineLocationCoordinates(req);
    setSavedPlaces(req);
    setRefresh([]);
    setStep("Planning");
    onCaculateRouting();
  }
  const revertPlanning = () => {
    setCurrentTripDay(0);
    initialPlanning();
    onCloseRevertConfirm();
    let originalTrip = isWeb && localStorage.DEFAULT_TRIP_PLAN ? JSON.parse(localStorage.DEFAULT_TRIP_PLAN) : JSON.parse(localStorage.TRIP_PLAN);
    let req: any = originalTrip;
    setStartPoint(originalTrip.day[0][0].name);
    // uncomment if u need coords getting from geocode api
    // onDefineLocationCoordinates(req);
    setSavedPlaces(req);
    rootStore.notify(t('REVERT_SUCCESS'), 'success');
  }
  const onDefineLocationCoordinates = (placeObj: any) => {
    placeObj.coords = [];
    placeObj.day.map((day: any) => {
      let dayCoordObj: any = [];
      day.map((p: any) => {
        Geocode.fromAddress(p.name + ' ' + placeObj.city).then((response: any) => {
          let latitude: number | null = null;
          let longtitude: number | null = null;
          if (response.results.length > 0) {
            const { lat, lng } = response.results[0].geometry.location;
            latitude = lat;
            longtitude = lng;
          };
          // assign lat & lng for each place at beginning
          let idx = placeObj.place.indexOf(placeObj.place.find((dp: any) => dp.name === p.name));
          placeObj.place[idx] = { ...placeObj.place[idx], latitude, longtitude, }
          dayCoordObj.push({ ...DefaultRoutingObj, name: p.name, latitude, longtitude });
        })
      })
      placeObj.coords.push(dayCoordObj);
      setSavedPlaces(placeObj);
      setRefresh([]);
    })
  }
  const isTripPlanningReady = () => {
    // uncomment if u need coords getting from geocode api
    // return savedPlaces && savedPlaces.place && savedPlaces.day && savedPlaces.coords;
    return savedPlaces && savedPlaces.place && savedPlaces.day;
  }
  const editPlaceInCurrentDay = (place: any, action: "Add" | "Remove") => {
    if (isTripPlanningReady()) {
      console.log(savedPlaces, "idx +", currentTripDay);
      let tempSavedPlaces = savedPlaces;
      let targetPlaceObj = savedPlaces.day[currentTripDay].find((gPlace: any) => gPlace.name === place.name);
      switch (action) {
        case "Add":
          if (!targetPlaceObj) {
            if (tempSavedPlaces.day[currentTripDay].length == MAX_PLACES_PER_DAY) rootStore.notify(t('EACH_DAY_CAN_CONTAINED_MAX_8_PLACES'), 'warning');
            else {
              tempSavedPlaces.day[currentTripDay].push(place);
              let latitude = savedPlaces.place.find((dp: any) => dp.name === place.name).latitude;
              let longtitude = savedPlaces.place.find((dp: any) => dp.name === place.name).longtitude;
              let req: any = { ...DefaultRoutingObj, name: place.name, latitude, longtitude };
              // Geocode.fromAddress(place.name).then((response: any) => {
              //   let latitude: number | null = null;
              //   let longtitude: number | null = null;
              //   if (response.results.length > 0) {
              //     const { lat, lng } = response.results[0].geometry.location;
              //     latitude = lat;
              //     longtitude = lng;
              //   };
              //   req.latitude = latitude;
              //   req.longtitude = longtitude;
              //   // if there is no coordinate slots of this trip day
              //   if (tempSavedPlaces.coords[currentTripDay]) tempSavedPlaces.coords[currentTripDay].push(req);
              //   else tempSavedPlaces.coords[currentTripDay] = [req];
              //   setSavedPlaces(tempSavedPlaces);
              // })
              console.log(req);
              // uncomment if u need coords getting from geocode api
              // if (tempSavedPlaces.coords[currentTripDay]) tempSavedPlaces.coords[currentTripDay].push(req);
              // else tempSavedPlaces.coords[currentTripDay] = [req];
              setSavedPlaces(tempSavedPlaces);
              setStartPoint(tempSavedPlaces.day[currentTripDay][0].name);
              localStorage.TRIP_PLAN = JSON.stringify(tempSavedPlaces);
              rootStore.notify(t('ATTRACTION_ADDED'), 'success');
            }
          }
          break;
        case "Remove":
          if (targetPlaceObj) {
            let idx = tempSavedPlaces.day[currentTripDay].indexOf(targetPlaceObj);
            tempSavedPlaces.day[currentTripDay].splice(idx, 1);
            // uncomment if u need coords getting from geocode api
            // tempSavedPlaces.coords[currentTripDay].splice(idx, 1);
            setStartPoint(tempSavedPlaces.day[currentTripDay][0] ? tempSavedPlaces.day[currentTripDay][0].name : "");
            localStorage.TRIP_PLAN = JSON.stringify(tempSavedPlaces);
            rootStore.notify(t('ATTRACTION_REMOVED'), 'success');
            RemoveProductFromBookingListByDay(currentTripDay);
            delete localStorage.TP_BOOKING;
          }
          setSavedPlaces(tempSavedPlaces);
          break;
      }
    }
    console.log(savedPlaces)
    setOpenRoutePlanner(false);
    setRefresh([]);
  }
  const onChangeSamePlaceSelectable = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsSamePlaceSelectable(event.target.checked);
    if (openRoutePlanner) startRouting(bookingStore.routePlanner);
  }
  // const onChangeTripDay = (direction?: "prev" | "next") => {
  //   switch (direction) {
  //     case "next":
  //       if (currentTripDay < savedPlaces.day.length) {
  //         setStartPoint(savedPlaces.day[currentTripDay + 1].length > 0 ? savedPlaces.day[currentTripDay + 1][0].name : "");
  //         if (openRoutePlanner) onCaculateRouting(currentTripDay + 1);
  //         setCurrentTripDay(currentTripDay + 1);
  //       }
  //       break;
  //     case "prev":
  //       if (currentTripDay > 0) {
  //         setStartPoint(savedPlaces.day[currentTripDay - 1].length > 0 ? savedPlaces.day[currentTripDay - 1][0].name : "");
  //         if (openRoutePlanner) onCaculateRouting(currentTripDay - 1);
  //         setCurrentTripDay(currentTripDay - 1);
  //       }
  //       break;
  //   }
  // }
  const onChangeTripDay = (targetDay: number) => {
    if (targetDay < savedPlaces.day.length) {
      setStartPoint(savedPlaces.day[targetDay].length > 0 ? savedPlaces.day[targetDay][0].name : "");
      if (openRoutePlanner) onCaculateRouting(targetDay);
      setCurrentTripDay(targetDay);
    }
  }
  const editDayTrip = (action: "Add" | "Delete") => {
    setOpenRoutePlanner(false);
    switch (action) {
      case "Add":
        if (currentTripDay + 2 > MAX_PLAN_DAYS_LIMIT) rootStore.notify(t('TRAVEL_DAY_EXI'), 'warning');
        else {
          savedPlaces.day.push([]);
          if (savedPlaces.day.length > 5) setShowMoreTabs(true);
          setCurrentTripDay(savedPlaces.day.length - 1);
          setStartPoint("");
          localStorage.TRIP_PLAN = JSON.stringify(savedPlaces);
          rootStore.notify(t('NEW_TRIP_DAY_ADDED'), 'success');
        }
        break;
      case "Delete":
        if (savedPlaces.day.length > 1) {
          let current: number = currentTripDay;
          let newCurrent: number = savedPlaces.day[current + 1] ? current : current - 1;
          setCurrentTripDay(newCurrent);
          savedPlaces.day.splice(current, 1);
          setStartPoint(savedPlaces.day[newCurrent][0].name);
          localStorage.TRIP_PLAN = JSON.stringify(savedPlaces);
          // uncomment if u need coords getting from geocode api
          // savedPlaces.coords.splice(current, 1);
          RemoveProductFromBookingListByDay(current);
          rootStore.notify(t('TRIP_DAY_DELETE'), 'success');
        }
        break;
    }
    let tabs = [];
    for (var i = 0; i < savedPlaces.day.length; i++) tabs.push(i);
    setDayTab(tabs);
    // if(savedPlaces.day)
    setRefresh([]);
  }
  const isPlaceDisabled = (placeName: string) => {
    let placeIsSelectedInSomeDay = false;
    if (!isSamePlaceSelectable) {
      for (var day of savedPlaces.day) {
        for (var p of day) {
          if (p.name === placeName) {
            placeIsSelectedInSomeDay = true;
            break;
          }
        }
      }
    }
    return placeIsSelectedInSomeDay || savedPlaces.day[currentTripDay].find((p: any) => p.name === placeName) ||
      savedPlaces.day[currentTripDay].length > MAX_PLACES_PER_DAY;
  }
  const onCloseRevertConfirm = () => {
    setRevertConfirm(false);
    setAnchorEl(null);
  }
  const onCloseDeleteConfirm = () => {
    setDeleteConfirm(false);
    setAnchorDeleteEl(null);
  }
  const constructPlacesUrl = () => {
    let url: string = "";
    if (savedPlaces.day[currentTripDay].length > 0) {
      savedPlaces.day[currentTripDay].map((p: any) => url += `${p.name}--`)
    }
    return url;
  }
  const onCaculateRouting = (day?: number) => {
    let currentDay: number = day != null ? day : currentTripDay;
    // console.log(currentDay, day, savedPlaces.coords[currentDay], savedPlaces.day[currentDay]);
    // uncomment if u need coords getting from geocode api
    // if (isTripPlanningReady() && savedPlaces.coords[currentDay] && (savedPlaces.coords[currentDay].length == savedPlaces.day[currentDay].length
    //   || savedPlaces.coords[currentDay].length > 0)) {
    if (isTripPlanningReady()) {
      setOpenRoutePlanner(true);
      if (savedPlaces.day[currentDay].length > 1) {
        let arrangedCoords: any[] = [];
        for (var p of savedPlaces.day[currentDay]) {
          // uncomment if u need coords getting from geocode api
          // let coord = savedPlaces.coords[currentDay].find((cp: any) => cp.name === p.name);
          arrangedCoords.push({ ...DefaultRoutingObj, name: p.name, latitude: p.latitude, longtitude: p.longitude });
        }
        console.log(arrangedCoords, savedPlaces.coords);
        bookingStore.runRoutePlanner(arrangedCoords)
          .then((planner: any) => startRouting(planner));
      } else rootStore.notify(t('YOU_NEED_ADD_AT_LEAST_TWO_LOCATIONS_TO_VIEW_THE_ROUTE_FOR_CURRENT_TRIP_DAY'), 'warning');
    } else setOpenRoutePlanner(false);
    // @ts-ignore
    // if (routeRef.current) routeRef.current.scrollIntoView(({ behavior: 'smooth' }));
  }
  const startRouting = (planner: any[], travel?: any) => {
    let wayPoints: any = [];
    for (var key in planner) {
      wayPoints.push({ location: { lat: +planner[key][1], lng: +planner[key][2] } })
    }
    if (wayPoints.length > 0) {
      const origin = wayPoints.shift().location;
      const destination = wayPoints.pop().location;
      const directionsService = new google.maps.DirectionsService()
      directionsService.route({
        origin: origin,
        destination: destination,
        waypoints: wayPoints,
        travelMode: travel ? travel : travelMode,
      }).then((results: any) => {
        setDirectionResponse(results);
        console.log(results.routes[0].legs);
        setRouteInfo(results.routes[0].legs);
      });
    }
  }
  const onChangeTravelMode = (travel: any) => {
    setTravelMode(travel);
    startRouting(bookingStore.routePlanner, travel);
  }
  const onChangeStartPoint = (event: SelectChangeEvent) => {
    let pName = event.target.value as string;
    setStartPoint(pName);
    let target = savedPlaces.day[currentTripDay].find((p: any) => p.name === pName);
    // uncomment if u need coords getting from geocode api
    // let targetCoords = savedPlaces.coords[currentTripDay].find((p: any) => p.name === pName);
    let idx = savedPlaces.day[currentTripDay].indexOf(target);
    savedPlaces.day[currentTripDay].splice(idx, 1);
    savedPlaces.day[currentTripDay].unshift(target);
    // uncomment if u need coords getting from geocode api
    // let idxCoords = savedPlaces.coords[currentTripDay].indexOf(targetCoords);
    // savedPlaces.coords[currentTripDay].splice(idxCoords, 1);
    // savedPlaces.coords[currentTripDay].unshift(targetCoords);
    localStorage.TRIP_PLAN = JSON.stringify(savedPlaces);
    rootStore.notify(`${t('START_POINT_IS_SET_TO')}: ${pName}`, 'success');
    setOpenRoutePlanner(false);
    setRefresh([]);
  }

  return (
    <Layout pageName={t('TRAVEL_PLANNING')}>
      <Box className={PADDING_HORIZONTAL}>
        {/* START STEP 1 - CHATGPT */}
        {step === "ChatGPT" ? <InititalTripStep onClick={initialPlanning} />
          //  END STEP 1 - CHATGPT 
          //  START STEP 2 - PLANNING
          : (step === "Planning" && isTripPlanningReady()) && <>
            <TravelPlanningStepper activeStep={1} />
            <Box sx={ITEM_PERFECT_INLINED} className="justify-between w-full mb-4">
              <button className="" onClick={() => setStep("ChatGPT")}>
                <Box sx={ITEM_PERFECT_INLINED}>
                  <ArrowBackIosRoundedIcon />
                  <p className="ml-2">{t('BACK_TO_YOUR_PLAN')}</p>
                </Box>
              </button>
            </Box>

            {/* Float Control Panel */}
            <Box className={`my-6`}>
              <Paper elevation={3} sx={{ p: 1 }}>
                <Box sx={ITEM_PERFECT_INLINED} className="justify-between">
                  <Box sx={ITEM_PERFECT_INLINED}>
                    <p className="px-3 font-bold">{t('DAY_A')} {currentTripDay + 1} {t('DAY_B')}</p>
                    {/* <button className="flex border-l border-l-gray-300 p-4" onClick={() => onCaculateRouting()}>
                      <MapIcon />
                      <p className="ml-2">{t('ROUTE_PLANNER')}</p>
                    </button> */}
                    <a
                      className="bg-green-500 text-white px-4 py-3 rounded-md"
                      href={`/travel-planning/booking-list?day=${currentTripDay}&places=${constructPlacesUrl()}`}
                      target="_blank">
                      <Box sx={ITEM_PERFECT_INLINED} className="justify-center">
                        <AddShoppingCartIcon />
                        <p className="ml-2">{t('WANT_TO_BOOK')}</p>
                      </Box>
                    </a>
                  </Box>
                  <Box sx={ITEM_PERFECT_INLINED}>
                    <a href={`/cart`} className="bg-sky-400 text-white px-4 py-3 rounded-md mr-2" target='_blank'>
                      <button className="flex">
                        <ShoppingCartCheckoutIcon />
                        <p className="ml-2">{t('PROCEED_TO_CHECKOUT')}</p>
                      </button>
                    </a>
                    <a href={`/travel-planning/trip-view`} className="bg-themeYellow text-white px-4 py-3 rounded-md" target='_blank'>
                      <button className="flex">
                        <RouteIcon />
                        <p className="ml-2">{t('TRIP_OVERVIEW')}</p>
                      </button>
                    </a>
                  </Box>
                </Box>
              </Paper>
            </Box>
            {/* End Float Control Panel */}

            <Box className="grid grid-cols-1 sm:grid-cols-2 gap-6">
              {/* Start Left Section */}
              <Paper elevation={3} className="bg-white border p-4">
                <Box className="grid grid-cols-5 2xl:grid-cols-6">
                  <Box sx={ITEM_PERFECT_INLINED} className="justify-center">
                    <Paper sx={{ borderRadius: '100%' }} className="border-8 border-gray-200 rounded-full py-5 w-20 text-center">
                      <p className="font-bold 2xl:text-lg">{t('STEP_1')}</p>
                    </Paper>
                  </Box>
                  <p className="col-span-4 2xl:col-span-5 font-bold 2xl:text-lg pt-7 pr-4">{t('STEP_2_EDIT_YOUR_TRIP')}</p>
                </Box>
                <Box className="p-4 pt-2">
                  {/* Control Panel */}
                  <Box sx={ITEM_PERFECT_INLINED} className="justify-between">
                    <Box sx={ITEM_PERFECT_INLINED}>
                      <p className="mr-2">{t('DAY_A')}{currentTripDay + 1}{t('DAY_B')} ({savedPlaces.day[currentTripDay].length} {t('ATTRACTIONS')})</p>
                      {savedPlaces.day[currentTripDay].length == 0 ? <Chip label={t('NEW_EMPTY_TRIP_DAY')} color="error" size="small" />
                        : (isWeb && localStorage.TP_BOOKING && JSON.parse(localStorage.TP_BOOKING).find((p: any) => +p.day === currentTripDay)) &&
                        <Box sx={ITEM_PERFECT_INLINED}>
                          <span className="text-green-500"><CheckCircleIcon color="inherit" /></span>
                          <p className="text-green-500 ml-1 pt-1">{t('BOOKED')}</p>
                        </Box>}
                    </Box>
                    <Box sx={ITEM_PERFECT_INLINED}>
                      <Tooltip title={t('ADD_A_NEW_TRIP_DAY')}>
                        <IconButton disabled={currentTripDay + 1 == MAX_PLAN_DAYS_LIMIT} onClick={() => editDayTrip("Add")}><AddCircleOutlineIcon /></IconButton>
                      </Tooltip>
                      <Tooltip title={t('DELETE_CURRENT_DAY')}>
                        <IconButton
                          disabled={savedPlaces.day.length == 1}
                          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                            setAnchorDeleteEl(event.currentTarget);
                            setDeleteConfirm(true);
                            if (openRoutePlanner) startRouting(bookingStore.routePlanner);
                          }}>
                          <DeleteForeverIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={t('REVERT_TRIP')}>
                        <IconButton onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                          setAnchorEl(event.currentTarget);
                          setRevertConfirm(true);
                          if (openRoutePlanner) startRouting(bookingStore.routePlanner);
                        }}>
                          <HistoryIcon />
                        </IconButton>
                      </Tooltip>
                      <Popover
                        open={deleteConfirm}
                        anchorEl={anchorDeleteEl}
                        onClose={onCloseDeleteConfirm}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                      >
                        <p className="p-4">{t('ARE_YOU_SURE_IN_DELETING_THIS_TRIP_DAY')}?</p>
                        <Box sx={{ padding: 2, paddingTop: 0, textAlign: "right" }}>
                          <VmButton onClick={() => {
                            setCurrentTripDay(0);
                            editDayTrip("Delete")
                            onCloseDeleteConfirm();
                          }}>
                            {t('DELETE_NOW')}
                          </VmButton>
                        </Box>
                      </Popover>
                      <Popover
                        open={revertConfirm}
                        anchorEl={anchorEl}
                        onClose={onCloseRevertConfirm}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                      >
                        <p className="p-4">{t('ARE_YOUR_SURE_TO_REVERT_YOUR_PLANNING_TO_DEFAULT')}?</p>
                        <Box sx={{ padding: 2, paddingTop: 0, textAlign: "right" }}>
                          <VmButton onClick={() => revertPlanning()}>
                            {t('REVERT_NOW')}
                          </VmButton>
                        </Box>
                      </Popover>
                    </Box>
                  </Box>
                  {/* End Control Panel */}

                  <Box className="grid grid-cols-5 gap-2">
                    {dayTab.filter((d: number, i: number) => i < 5).map((d: number) => (
                      <button
                        key={`day_tab_${d}`}
                        onClick={() => onChangeTripDay(d)}
                        className={`w-full py-1 px-2 border ${currentTripDay == d ? 'text-white bg-themeYellow border-themeYellow' : 'text-black bg-white border-gray-200'} shadow-lg`}>
                        {t('DAY_A')}{d + 1}{t('DAY_B')} {(isWeb && localStorage.TP_BOOKING && JSON.parse(localStorage.TP_BOOKING).find((p: any) => +p.day === d)) && <span className={`${currentTripDay == d ? 'text-white' : 'text-green-500'}`}><CheckCircleIcon color="inherit" /></span>}
                      </button>
                    ))}
                  </Box>
                  {/* If day tab */}
                  {dayTab.length > 5 && <>
                    <SlideDown closed={!showMoreTabs}>
                      <Box className="grid grid-cols-5 gap-2 mt-2">
                        {dayTab.filter((d: number, i: number) => i >= 5).map((d: number) => (
                          <button
                            key={`day_tab_${d}`}
                            onClick={() => onChangeTripDay(d)}
                            className={`w-full py-1 px-2 border ${currentTripDay == d ? 'text-white bg-themeYellow border-themeYellow' : 'text-black bg-white border-gray-200'} shadow-lg`}>
                            {t('DAY_A')}{d + 1}{t('DAY_B')}
                          </button>
                        ))}
                      </Box>
                    </SlideDown>
                    <Box className="flex justify-center mt-2">
                      <button className="text-themeYellow" onClick={() => setShowMoreTabs(!showMoreTabs)}>
                        {showMoreTabs ? <Box sx={ITEM_PERFECT_INLINED}>
                          <ExpandLessIcon color="inherit" />
                          <p>{t('SHOW_LESS')}</p>
                        </Box> : <Box sx={ITEM_PERFECT_INLINED}>
                          <ExpandMoreIcon color="inherit" />
                          <p>{t('SHOW_MORE')}</p>
                        </Box>}
                      </button>
                    </Box>
                  </>}
                  {/* <Box className="my-2 pr-2">
                    <FormControl sx={{ width: '100%' }} size="small">
                      <InputLabel>{t('SET_UR_START_POINT')}</InputLabel>
                      <Select
                        label={t('SET_UR_START_POINT')}
                        value={startPoint}
                        sx={{ width: '100%' }}
                        size="small"
                        onChange={onChangeStartPoint}
                      >
                        {savedPlaces.day[currentTripDay].map((p: any, i: number) => (
                          <MenuItem key={`start_point_place_name_${i}`} value={p.name}>{p.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box> */}
                  <Box className="mb-4 overflow-auto pr-1 pb-2" sx={{ maxHeight: 500 }}>
                    {savedPlaces.day[currentTripDay].map((p: any, i: number) => (
                      <Paper key={`day_location_${i}`} sx={{ mt: 2, border: '2px solid red' }}>
                        <Box
                          // disabled={tripDayMode === "View"}
                          className="text-left p-2 w-full"
                        // onClick={() => editPlaceInCurrentDay(p, "Remove")}
                        >
                          <Box>
                            <p className="mb-1 font-bold">{p.name}</p>
                            {/* @ts-ignore */}
                            <button onClick={() => onChangeStartPoint({ target: { value: p.name } })} className="hover:underline">
                              <Box sx={ITEM_PERFECT_INLINED} className={`${startPoint === p.name ? 'text-themeYellow' : 'text-gray-500'}`}>
                                <span className="" style={{ marginTop: -5 }}><FlagIcon color="inherit" /></span>
                                <span className="text-sm ml-1">{startPoint === p.name ? t('START_POINT') : t('SET_AS_START_POINT')}</span>
                              </Box>
                            </button>
                          </Box>
                          <p className="font-light text-sm text-gray-500 mt-2">{p.description}</p>
                          <Box sx={ITEM_PERFECT_INLINED} className={`justify-between ${tripDayMode === "View" ? 'text-gray-500' : 'text-red-500'}`}>
                            <Box sx={ITEM_PERFECT_INLINED}>
                              {IsAttractionBookedInTPBooking(p.name, `${currentTripDay}`) && <>
                                <span className="text-green-500"><CheckCircleIcon color="inherit" /></span>
                                <p className="text-green-500 ml-1 pt-1">{t('BOOKED')}</p>
                              </>}
                            </Box>
                            <IconButton onClick={() => editPlaceInCurrentDay(p, "Remove")} disabled={tripDayMode === "View"}>
                              <RemoveCircleIcon color="error" fontSize="large" />
                            </IconButton>
                          </Box>
                        </Box>
                      </Paper>
                    ))}
                  </Box>
                </Box>
                {/* Control Panel */}
              </Paper>
              {/* End Left Section */}
              {/* Start Right Section */}
              <Paper elevation={3} sx={{ height: 'fit-content' }} className="bg-white border p-4">
                {/* <Box className="grid grid-cols-5 2xl:grid-cols-6">
                  <Paper sx={{ borderRadius: '100%' }} className="border-8 border-gray-200 rounded-full py-5 w-20 text-center">
                    <p className="font-bold 2xl:text-lg">{t('STEP_1')}</p>
                  </Paper>
                  <p className="col-span-4 2xl:col-span-5 font-bold 2xl:text-lg pt-7 pr-4">{t('STEP_1_ADD_RECOMMENED_PLACES_TO_YOUR_TRIP')} ({savedPlaces.place.length})</p>
                </Box> */}
                <Box sx={ITEM_PERFECT_INLINED}>
                  <span className="text-themeYellow">
                    <PlaylistAddCircleIcon fontSize="large" />
                  </span>
                  <p className="ml-2 font-bold">{t('STEP_1_ADD_RECOMMENED_PLACES_TO_YOUR_TRIP')} ({savedPlaces.place.length})</p>
                </Box>
                <Box sx={ITEM_PERFECT_INLINED} className="mt-2">
                  <FormControlLabel label={t('SAME_PLACES_ALLOWED')}
                    control={<Switch
                      checked={isSamePlaceSelectable}
                      onChange={onChangeSamePlaceSelectable}
                      color="primary"
                    />} />
                </Box>
                <Box className="overflow-auto pr-1 pb-2" sx={{ maxHeight: 500 }}>
                  {savedPlaces.place.filter((p: any) => !isPlaceDisabled(p.name)).length == 0 ? <Box className="my-6 text-gray-500 border-t py-4" textAlign={"center"}>
                    <span className="text-themeYellow"><CircleNotificationsIcon fontSize="large" color="inherit" /></span>
                    <p className="mt-4 uppercase">{t('ALL_RECOMMENED_ATTRACTIONS_HAS_BEEN_SELECTED')}</p>
                    <p className="mt-2 text-sm">{t('TIP_U_CAN_TURN_ON_THE_SAME_PLACES_ALLOWED_SWITCH_ABOVE_TO_ALLOW_YOURSELF_TO_SELECT_SAME_ATTRACTIONS_FOR_DIFFERENT_TRAVEL_DAYS')}</p>
                  </Box> : savedPlaces.place.filter((p: any) => !isPlaceDisabled(p.name)).map((p: any, i: number) => (
                    <Paper key={`location_${i}`} sx={{ mt: 2, border: '2px solid lightgreen' }}>
                      <Box
                        // disabled={isPlaceDisabled(p.name)}
                        className={`w-full ${isPlaceDisabled(p.name) ? 'cursor-not-allowed' : ''} 
                        transition-all text-left p-2`}
                      // onClick={() => editPlaceInCurrentDay(p, "Add")}
                      >
                        <p className="mb-2 font-bold">{p.name}</p>
                        <p className="font-light text-sm text-gray-500">{p.description}</p>
                        <Box sx={ITEM_PERFECT_INLINED} className={`justify-end ${isPlaceDisabled(p.name) ? 'text-gray-500' : 'text-green-500'}`}>
                          <IconButton onClick={() => editPlaceInCurrentDay(p, "Add")} disabled={tripDayMode === "View"}>
                            {isPlaceDisabled(p.name) ? <DoNotDisturbIcon color="success" fontSize="large" /> : <AddCircleIcon color="success" fontSize="large" />}
                          </IconButton>
                        </Box>
                      </Box>
                    </Paper>
                  ))}
                </Box>
              </Paper>
              {/* End Right Section */}
            </Box>

            {/* Float Control Panel */}
            <Box className={`mt-6`}>
              <Paper elevation={3} sx={{ p: 1 }}>
                <Box sx={ITEM_PERFECT_INLINED} className="justify-between">
                  <Box sx={ITEM_PERFECT_INLINED}>
                    <p className="px-3 font-bold">{t('DAY_A')} {currentTripDay + 1} {t('DAY_B')}</p>
                    {/* <button className="flex border-l border-l-gray-300 p-4" onClick={() => onCaculateRouting()}>
                      <MapIcon />
                      <p className="ml-2">{t('ROUTE_PLANNER')}</p>
                    </button> */}
                    <a
                      className="bg-green-500 text-white px-4 py-3 rounded-md"
                      href={`/travel-planning/booking-list?day=${currentTripDay}&places=${constructPlacesUrl()}`}
                      target="_blank">
                      <Box sx={ITEM_PERFECT_INLINED} className="justify-center">
                        <AddShoppingCartIcon />
                        <p className="ml-2">{t('WANT_TO_BOOK')}</p>
                      </Box>
                    </a>
                  </Box>
                  <Box sx={ITEM_PERFECT_INLINED}>
                    <a href={`/cart`} className="bg-sky-400 text-white px-4 py-3 rounded-md mr-2" target='_blank'>
                      <button className="flex">
                        <ShoppingCartCheckoutIcon />
                        <p className="ml-2">{t('PROCEED_TO_CHECKOUT')}</p>
                      </button>
                    </a>
                    <a href={`/travel-planning/trip-view`} className="bg-themeYellow text-white px-4 py-3 rounded-md" target='_blank'>
                      <button className="flex">
                        <RouteIcon />
                        <p className="ml-2">{t('TRIP_OVERVIEW')}</p>
                      </button>
                    </a>
                  </Box>
                </Box>
              </Paper>
            </Box>
            {/* End Float Control Panel */}

            {/* Map Area */}
            <Box ref={routeRef} />
            {openRoutePlanner && <Box className="mt-6 pt-6 border-t border-gray-200">
              {bookingStore.routing ? <Box className="text-center">
                <CircularProgress />
                <p className="mt-4">{t('GENERATING_RECOMMENED_ROUTE')}...</p>
              </Box>
                : <>
                  <Box sx={ITEM_PERFECT_INLINED} className="justify-between mb-2">
                    <p className="font-bold 2xl:text-lg">{t('ROUTE_PLANNER')}: {t('DAY_A')}{currentTripDay + 1}{t('DAY_B')}</p>
                    <Box sx={ITEM_PERFECT_INLINED}>
                      <p className="mr-4">{t('TRAVEL_BY')}:</p>
                      <button onClick={() => onChangeTravelMode(google.maps.TravelMode.DRIVING)} className={`flex py-2 px-4 ${travelMode === google.maps.TravelMode.DRIVING ? 'bg-orange-400 text-white' : 'text-black border'} shadow-lg`}>
                        <DirectionsCarIcon color="inherit" fontSize="small" />
                        <p className="ml-2 text-sm">{t('DRIVING')}</p>
                      </button>
                      {/* <button onClick={() => onChangeTravelMode(google.maps.TravelMode.TRANSIT)} className={`flex mr-2 py-2 px-4 ${travelMode === google.maps.TravelMode.TRANSIT ? 'bg-orange-400 text-white' : 'text-black border'} shadow-lg`}>
                    <DirectionsBusIcon color="inherit" fontSize="small" />
                    <p className="ml-2 text-sm">{t('PUBLIC_TRANSPORT')}</p>
                  </button>
                  <button onClick={() => onChangeTravelMode(google.maps.TravelMode.WALKING)} className={`flex mr-2 py-2 px-4 ${travelMode === google.maps.TravelMode.WALKING ? 'bg-orange-400 text-white' : 'text-black border'} shadow-lg`}>
                    <DirectionsWalkIcon color="inherit" fontSize="small" />
                    <p className="ml-2 text-sm">{t('WALKING')}</p>
                  </button> */}
                    </Box>
                  </Box>
                  <Box className="grid grid-cols-2 gap-4">
                    {routeInfo && <Box className="bg-white shadow-lg border mb-2 p-2 border-t-8 border-t-themeYellow pb-4" sx={{ height: 'fit-content' }}>
                      <p className="font-bold mb-4">{t('ROUTE_SUMMARY')}</p>
                      <table>
                        {bookingStore.routePlanner.length > 0 && routeInfo.length > 0 && routeInfo.map((route: any, i: number) => (
                          <>
                            {i == 0 && <tr>
                              <td className="w-1/3 text-right pr-3 pb-4">{bookingStore.routePlanner[i][0]} <span className="text-white bg-red-500 rounded-full px-1">{alphabet[i]}</span></td>
                              <td className="w-2/3 pr-3 pb-4">
                                <b className="underline">{t('START')}:</b> <span className="no-underline">{route.start_address}</span> <span className="text-white bg-red-500 rounded-full px-1">{alphabet[i]}</span>
                              </td>
                            </tr>}
                            <tr>
                              <td className="w-1/3 text-right pb-4 pr-2"><SouthIcon /></td>
                              <td className="w-2/3 pr-3 pb-4 grid grid-cols-2 gap-2">
                                <Box>
                                  <p className="font-bold underline">{t('TOTAL_DISTANCE')}:</p>
                                  <Box sx={ITEM_PERFECT_INLINED}>
                                    <span className="text-sky-500"><RouteIcon color="inherit" /></span>
                                    <p className="ml-2">{route.distance.text}</p>
                                  </Box>
                                </Box>
                                <Box>
                                  <p className="font-bold underline">{t('DURATION')}:</p>
                                  <Box sx={ITEM_PERFECT_INLINED}>
                                    <span className="text-themeYellow"><AccessTimeIcon color="inherit" /></span>
                                    <p className="ml-2">{route.duration.text}</p>
                                  </Box>
                                </Box>
                              </td>
                            </tr>
                            <tr>
                              <td className="w-1/3 text-right pr-3 pb-4"><p>{bookingStore.routePlanner[i + 1][0]} <span className="text-white bg-red-500 rounded-full px-1">{alphabet[i + 1]}</span></p></td>
                              <td className="w-2/3 pr-3 pb-4">
                                <b className="underline">{routeInfo[i + 1] ? t('WAYPOINT') : t('END')}:</b> <span className="no-underline">{route.end_address}</span> <span className="text-white bg-red-500 rounded-full px-1">{alphabet[i + 1]}</span>
                              </td>
                            </tr>
                          </>
                        ))}
                      </table>
                      {/* <Box className="grid grid-cols-3 gap-6 py-2">
                        <Box className="text-right border-r pr-4">
                          {bookingStore.routePlanner.map((info: string[], i: number) => (
                            <>
                              <p className="mb-2" key={`dest_info_route_${i}`}>{info[0]} <span className="text-white bg-red-500 rounded-full px-1">{alphabet[i]}</span></p>
                              {bookingStore.routePlanner[i + 1] && <Box className="mb-2"><SouthIcon /></Box>}
                            </>
                          ))}
                        </Box>
                        <Box className="col-span-2">
                          {routeInfo.length > 0 && routeInfo.map((route: any, i: number) => (
                            <Box key={`route_${i}`} className={``}>
                              {i == 0 && <p>
                                <b className="underline">{t('START')}:</b> <span className="no-underline">{route.start_address}</span> <span className="text-white bg-red-500 rounded-full px-1">{alphabet[i]}</span>
                              </p>}
                              <Box className="grid grid-cols-2 gap-2 my-2">
                                <Box>
                                  <p className="font-bold underline">{t('TOTAL_DISTANCE')}:</p>
                                  <Box sx={ITEM_PERFECT_INLINED}>
                                    <span className="text-sky-500"><RouteIcon color="inherit" /></span>
                                    <p className="ml-2">{route.distance.text}</p>
                                  </Box>
                                </Box>
                                <Box>
                                  <p className="font-bold underline">{t('DURATION')}:</p>
                                  <Box sx={ITEM_PERFECT_INLINED}>
                                    <span className="text-themeYellow"><AccessTimeIcon color="inherit" /></span>
                                    <p className="ml-2">{route.duration.text}</p>
                                  </Box>
                                </Box>
                              </Box>
                              <p><b className="underline">{routeInfo[i + 1] ? t('WAYPOINT') : t('END')}:</b> <span className="no-underline">{route.end_address}</span> <span className="text-white bg-red-500 rounded-full px-1">{alphabet[i + 1]}</span></p>
                            </Box>
                          ))}
                        </Box>
                      </Box> */}
                    </Box>}
                    {isLoaded && <GoogleMap
                      mapContainerStyle={{ height: "100%", minHeight: 400 }}
                      center={{ lat: -31.363, lng: 151.044 }}
                      zoom={6}
                    >
                      {directionResponse && <DirectionsRenderer directions={directionResponse} />}
                    </GoogleMap>}
                  </Box>
                </>}
            </Box>}
            {/* End Map Area */}
          </>}
        {/* END STEP 2 - PLANNING */}
      </Box >
    </Layout >
  )
});

export default TravelPlanningIndexPage;
