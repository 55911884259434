import React, { useCallback, useEffect, useRef, useState } from "react"
import Layout from "../shared-layout";
import { Alert, Autocomplete, Avatar, Box, InputBase, ListItem, ListItemText, FormControlLabel, CircularProgress, IconButton, InputAdornment, Radio, RadioGroup, TextField, Typography, Checkbox, Grid, Chip, Tooltip, setRef, Switch, Popover, SelectChangeEvent, Select, MenuItem, Button } from "@mui/material";
import moment from "moment";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
// @ts-ignore
import BannerImg from "../../images/banner.jpg";
// @ts-ignore
import FamilyTrip from "../../images/family-trip.jpg";
// @ts-ignore
import BusinessTrip from "../../images/business-trip.jpg";
// @ts-ignore
import HoneyMoon from "../../images/honey-moon.jpg";
// @ts-ignore
import Adelaide from "../../images/adelaide.jpg";
// @ts-ignore
import Brisbane from "../../images/brisbane.jpg";
// @ts-ignore
import Cairns from "../../images/cairns.jpg";
// @ts-ignore
import Canberra from "../../images/canberra.jpg";
// @ts-ignore
import Darwin from "../../images/darwin.jpg";
// @ts-ignore
import Perth from "../../images/perth.jpg";
// @ts-ignore
import Melbourne from "../../images/melbourne.jpg";
// @ts-ignore
import Sydney from "../../images/sydney.jpg";
// @ts-ignore
import Food from "../../images/food.jpg";
// @ts-ignore
import AmusementPark from "../../images/amusement-park.jpg";
// @ts-ignore
import Natural from "../../images/natural.jpg";
// @ts-ignore
import History from "../../images/History.jpg";
import { ITEM_PERFECT_INLINED, PADDING_HORIZONTAL, THEME_YELLOW } from "../../constants/style";
import VmButton from "../shared-button";
import useStores from "../../hooks/use-stores";
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import { googleMapApiKey, GPT_MODEL } from "../../constants/settings";
import { GoogleMap, useJsApiLoader, DirectionsRenderer } from '@react-google-maps/api';
import { DefaultRoutingObj, Destinations, InterestAreas, TravelPurposes, TripInfo } from "../../utilities/travel-planning";
import { IsEmptyStr, IsOnlyDigits } from "../../utilities/field-validation";
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { isWeb } from "../../utilities/platform";
import { MAX_PLAN_DAYS_LIMIT } from "../../constants/options";
import { AUDDateFormat } from "../../utilities/cart-item";
import TravelPlanningStepper from "../travel-planning-stepper";

const InititalTripStep = observer(({ onClick }: { onClick: (tripInfo: any) => any }) => {
  const { rootStore, bookingStore } = useStores();
  const { t }: any = useTranslation();
  const [days, setDays] = useState<string>("");
  const [fromDate, setFromDate] = useState<string>("");
  const [dest, setDest] = useState<string>("");
  const [destList, setDestList] = useState<string[]>([]);
  const [purpose, setPurpose] = useState<string>("");
  const [interest, setInterest] = useState<string>("");
  const [interestList, setInterestList] = useState<string[]>([]);
  const [numAttractions, setNumAttractions] = useState<string>("");
  const [specialRequest, setSpecialRequest] = useState<string>("");
  const [tripInfoJson, setTripInfoJson] = useState<any>();
  const [showErr, setShowErr] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<any>([]);

  const onChangePurpose = (event: SelectChangeEvent) => setPurpose(event.target.value as string);
  const onChangeInterest = (event: SelectChangeEvent) => setInterest(event.target.value as string);
  const onChangeDest = (event: SelectChangeEvent) => setDest(event.target.value as string);

  useEffect(() => {
    if (isWeb && localStorage.TRIP_PLAN) setTripInfoJson(JSON.parse(localStorage.TRIP_PLAN));
  }, []);

  const onCheckValidFields = () => {
    if (IsEmptyStr(days) || IsEmptyStr(fromDate)|| IsEmptyStr(dest) || IsEmptyStr(purpose) || interestList.length == 0) {
      setShowErr(true);
      // if (!IsOnlyDigits(days) || !IsOnlyDigits(numAttractions)) rootStore.notify(t('INVALID_INPUT_DETECTED'), 'warning');
      rootStore.notify(t('PLS_FILL_IN_ALL_FIELDS'), 'warning');
      return false;
    }
    if (+days > MAX_PLAN_DAYS_LIMIT) {
      rootStore.notify(t('THE_MAXIMUM_TRIP_DAYS_CAN_ONLY_PLAN_FOR_14_DAYS'), 'warning')
      return false;
    }
    return true;
  }

  const onProcessTripInfo = () => {
    if (!onCheckValidFields()) return;
    delete localStorage.TRIP_PLAN;
    delete localStorage.DEFAULT_TRIP_PLAN;
    delete localStorage.TP_BOOKING;
    let userMsg = "I want to go to ";
    // allow user to pick multiple destinations
    // userMsg += `${destList.map((d: any) => ` ${d}`)} `;
    userMsg += `${dest} `;
    userMsg += `for ${days} day from ${fromDate} for my ${purpose}. `;
    userMsg += `I'm interested on ${interestList.map((d: any) => `${d}`)}. `;
    userMsg += `You must return me a JSON code in the format as this 
    {
      day: [
        [{ name: "A", description: "This is area A", longitude: 0, latitude: 0 }], 
      ]
    }
    You must plan ${3 * +days} recommended attractions over ${days} trip day with relevant descriptions, precise longitude & latitude and store into "day" in the above JSON.`;
    let reqMessage = JSON.stringify({
      "model": GPT_MODEL,
      "temperature": 0,
      "messages": [
        { role: "system", content: "You are a text processing program and just need to return the results." },
        { role: "user", content: userMsg }
      ],
    });
    console.log(userMsg);
    setTripInfoJson(null);
    // return;
    bookingStore.sendMessageToChatGPT(reqMessage)
      .then((response: any) => {
        let message = response.choices[0].message.content;
        message = message.split("```")[1];
        let tripJson: any = JSON.parse(message);
        let tripDayList: any[] = [];
        let attractionList: any[] = [];
        tripJson.day.map((day: any) => {
          let tripDay: any = [];
          day.map((p: any) => {
            if (!IsEmptyStr(p.name)) {
              tripDay.push(p);
              attractionList.push(p);
            }
          })
          tripDayList.push(tripDay);
        })
        tripJson.day = tripDayList;
        tripJson.place = attractionList;
        tripJson.city = dest;
        console.log("CHATGPT_RESPONSE", JSON.parse(message), tripJson);
        setTripInfoJson(tripJson);
        localStorage.TRIP_PLAN = JSON.stringify(tripJson);
        localStorage.DEFAULT_TRIP_PLAN = JSON.stringify(tripJson);
        onClick(tripJson);
      });
  }

  return (
    <>
      <TravelPlanningStepper activeStep={0} />
      {bookingStore.replyingChatGPT ? <Box className="text-center mt-20">
        <CircularProgress />
        <p className="mt-4">{t('GENERATING_YOUR_PERSONAL_TRAVEL_PLAN')}...</p>
        <p className="mt-2">{t('PLEASE_BE_PATIENT')}</p>
        <p className="mt-4">{t('NOTE_THE_NUMBER_OF_TRIP_DAYS_COULD_AFFECT_GENERATING_SPEED')}</p>
      </Box>
        : <>
          {tripInfoJson && <Box sx={ITEM_PERFECT_INLINED} className="justify-end">
            <button className="mb-4" onClick={() => onClick(tripInfoJson)}>
              <Box sx={ITEM_PERFECT_INLINED}>
                <p className="mr-2">{t('BACK_TO_PREVIOUS_TRIP_PLANNING')}</p>
                <ArrowForwardIosRoundedIcon />
              </Box>
            </button>
          </Box>}
          <Box className="grid grid-cols-1 sm:grid-cols-5 rounded-3xl shadow-lg border">
            <Box className="col-span-2 rounded-3xl overflow-hidden m-4 mr-0">
              <Box className="min-h-60 w-full h-full relative">
                <img src={BannerImg} alt="" className="h-full w-full object-cover" />
                {/* <Box className="absolute top-0 right-0 bottom-0 left-0 text-white" style={{ background: `rgba(0,0,0,0.5)` }}>
                  <Box className="absolute left-0 top-1/2 translate-y-neg-1/2 w-full p-4">
                    <p className="text-xl 2xl:text-2xl mb-4 uppercase">{t('PRODUCE_YOUR_UNIQUE_PLAN_USING_OUR_TRAVEL_PLANNING')}</p>
                    <p className="text-lg 2xl:text-xl">- {t('PLAN_AUTOMATION')}</p>
                    <p className="text-lg 2xl:text-xl">- {t('FULL_CUSTOMISED_TRAVEL_PLAN')}</p>
                    <p className="text-lg 2xl:text-xl">- {t('CLEAR_ROUTE_PLANNER')}</p>
                  </Box>
                </Box> */}
              </Box>
            </Box>
            <Box className="col-span-3 rounded-r-3xl bg-white p-8">
              <Box>
                <Box className="mb-2 border-l-8 border-themeYellow pl-4">{t('WHAT_IS_YOUR_TRAVELLING_PURPOSES')} <span className="text-red-500">*</span></Box>
                <Box sx={ITEM_PERFECT_INLINED} className={`${showErr && IsEmptyStr(purpose) ? 'border-red-500' : 'border-white'} border`}>
                  {TravelPurposes.map((tp: any, i: number) => (
                    <Box key={`travel_purpose_${i}`} className="mr-4">
                      <button className={`${purpose === tp.value ? 'border-sky-400 bg-sky-400 text-white' : 'border-black border-black'} border px-2 py-1 rounded-md object-cover transition-all`} onClick={() => setPurpose(tp.value)}>{tp.label}</button>
                      {/* <IconButton onClick={() => setPurpose(tp.value)}>
                        <img src={tp.label === t('FAMILY_TRIP') ? FamilyTrip
                          : tp.label === t('BUSINESS_TRIP') ? BusinessTrip : HoneyMoon} className={`${purpose === tp.value && 'border-4 border-sky-400'} w-20 h-20 rounded-full object-cover transition-all`} />
                      </IconButton>
                      <p className={`${purpose === tp.value && 'text-sky-400'} text-center text-xs transition-all`}>{tp.label}</p> */}
                    </Box>))}
                </Box>
              </Box>
              <Box className="mt-6">
                <Box className="mb-2 border-l-8 border-themeYellow pl-4">{t('WHICH_CTS_YOU_ARE_LOOKING_TO_TRAVEL')} <span className="text-red-500">*</span></Box>
                <Box sx={ITEM_PERFECT_INLINED} className={`${showErr && IsEmptyStr(dest) ? 'border-red-500' : 'border-white'} border`}>
                  {Destinations.map((d: any, i: number) => (
                    <Box key={`travel_purpose_${i}`} className="">
                      <IconButton onClick={() => setDest(d.value)}>
                        <img src={d.label === t('ADELAIDE') ? Adelaide : d.label === t('BRISBANE') ?
                          Brisbane : d.label === t('CAIRNS') ? Cairns : d.label === t('CANBERRA') ? Canberra
                            : d.label === t('DARWIN') ? Darwin : d.label === t('PERTH') ? Perth
                              : d.label === t('MELBOURNE') ? Melbourne : Sydney} alt="" className={`${dest === d.value && 'border-4 border-sky-400'} w-20 h-28 object-cover rounded-lg transition-all`} />
                      </IconButton>
                      <p className={`${dest === d.value && 'text-sky-400'} text-center text-xs transition-all`}>{d.label}</p>
                    </Box>
                  ))}
                  {/* <Box className="col-span-4">
                    <Select
                      // label={t('SELECT') + ' *'}
                      value={dest}
                      size="medium"
                      onChange={onChangeDest}
                      sx={{ width: '100%' }}
                      error={showErr && IsEmptyStr(dest)}
                    >
                      {Destinations.map((d: any, i: number) => (<MenuItem key={`dest_${i}`} value={d.valueEn}>{d.valueEn}</MenuItem>))}
                    </Select>
                  </Box> */}
                  {/* <Button
                  variant="outlined"
                  disabled={IsEmptyStr(dest)}
                  onClick={() => {
                    if (!destList.includes(dest)) destList.push(dest);
                    setRefresh([]);
                  }}>
                  {t('ADD')}
                </Button> */}
                </Box>
                {/* <Box className="grid grid-cols-4 sm:grid-cols-4 gap-2 sm:gap-4 mt-4 border rounded-sm p-2">
                {destList.length > 0 ? destList.map((area: string, i: number) => (
                  <Chip
                    color={"success"}
                    key={`area_${i}`}
                    label={area}
                    variant="outlined"
                    sx={{ width: '100%' }}
                    onDelete={() => {
                      destList.splice(i, 1);
                      setRefresh([]);
                    }}
                  />
                )) : <p className="text-center col-span-4 sm:col-span-5">{t('NO_CTS_SELECTED')}</p>}
              </Box> */}
              </Box>
              <Box className="mt-6">
                <Box className="mb-2 border-l-8 border-themeYellow pl-4">{t('HOW_MANY_TRIP_DAYS_YOUR_PLAN_FOR_MAX_14_DAYS')} <span className="text-red-500">*</span></Box>
                <Box sx={ITEM_PERFECT_INLINED}>
                  {["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14"].map((d: string, i: number) => (
                    <button
                      key={`d_${i}`}
                      className={`${showErr && IsEmptyStr(days) ? 'border-red-500' : days === d ? 'bg-sky-400 text-white border-sky-400' : 'bg-white border-black'} 
                      hover:bg-sky-400 hover:text-white hover:border-sky-400 transition-all py-2 w-10 border ${i != 0 && 'border-l-0'}`}
                      onClick={() => setDays(d)}
                    >
                      {d}
                    </button>
                  ))}
                  <>
                    {/* <TextField
                    value={days}
                    onChange={(event: any) => setDays(event.target.value)}
                    sx={{ width: 200 }}
                    // label={t('HOW_MANY_TRIP_DAYS_YOUR_PLAN_FOR')}
                    type="number"
                    error={showErr && (IsEmptyStr(days) || !IsOnlyDigits(days) || +days > MAX_PLAN_DAYS_LIMIT)}
                  />
                  <p className="ml-4">{t('DAYS')}</p> */}
                  </>
                </Box>
              </Box>
              <Box className="mt-6">
                <Box className="mb-2 border-l-8 border-themeYellow pl-4">{t('WHATS_YOUR_TRAVEL_DATE')} <span className="text-red-500">*</span></Box>
                <TextField
                  value={fromDate}
                  type="date"
                  onChange={(event: any) => setFromDate(event.target.value)}
                  // label={t('WHATS_YOUR_TRAVEL_DATE')}
                  InputLabelProps={{ shrink: true }}
                  sx={{ width: '100%' }}
                  error={showErr && IsEmptyStr(fromDate)}
                  InputProps={{ inputProps: { min: moment().add(1, 'days').format("YYYY-MM-DD") } }}
                />
              </Box>
              {/* <Box className="mt-8">
                <Box className="mb-2 border-l-8 border-themeYellow pl-4">{t('WHAT_IS_THE_MINUMUM_ATTRACTIONS_THAT_YOU_WANT_TO_VISIT')} <span className="text-red-500">*</span></Box>
                <Box sx={ITEM_PERFECT_INLINED}>
                  <TextField
                    value={numAttractions}
                    type="number"
                    onChange={(event: any) => setNumAttractions(event.target.value)}
                    sx={{ width: 200 }}
                    error={showErr && (IsEmptyStr(numAttractions) || !IsOnlyDigits(numAttractions))}
                  />
                  <p className="ml-4">{t('ATTRACTIONS')}</p>
                </Box>
              </Box> */}
              <Box className="mt-6">
                <Box className="mb-2 border-l-8 border-themeYellow pl-4">{t('WHAT_AREAS_YOU_ARE_MOSTLY_INTERESTED_ON')} <span className="text-red-500">*</span></Box>
                <Box sx={ITEM_PERFECT_INLINED} className={`${showErr && interestList.length == 0 ? 'border-red-500' : 'border-white'} border`}>
                  {InterestAreas.map((ia: any, i: number) => (
                    <Box key={`ia_${i}`} className="">
                      <IconButton onClick={() => {
                        if (!interestList.includes(ia.value)) interestList.push(ia.value);
                        else {
                          let idx = interestList.indexOf(ia.value);
                          interestList.splice(idx, 1);
                        }
                        setRefresh([]);
                      }}>
                        <img src={ia.label === t('TASTE_LOCAL_FOOD') ? Food : ia.label === t('AMUSEMENT_PARK') ?
                          AmusementPark : ia.label === t('NATURAL_ATTRACTIONS') ? Natural : History}
                          alt=""
                          className={`${interestList.includes(ia.value) && 'border-4 border-sky-400'} w-32 h-16 object-cover rounded-lg transition-all`} />
                      </IconButton>
                      <p className={`${interestList.includes(ia.value) && 'text-sky-400'} text-center text-xs transition-all`}>{ia.label}</p>
                    </Box>
                  ))}
                </Box>
                {/* <Box className="grid grid-cols-4 gap-4">
                  <Box className="col-span-3">
                    <Select
                      // label={t('SELECT') + ' *'}
                      value={interest}
                      size="medium"
                      onChange={onChangeInterest}
                      sx={{ width: '100%' }}
                    >
                      {InterestAreas.map((ia: any, i: number) => (<MenuItem key={`travel_purpose_${i}`} value={ia.valueEn}>{ia.valueEn}</MenuItem>))}
                    </Select>
                  </Box>
                  <Button
                    variant="outlined"
                    disabled={IsEmptyStr(interest)}
                    onClick={() => {
                      if (!interestList.includes(interest)) interestList.push(interest);
                      setInterest("");
                      setRefresh([]);
                    }}>
                    {t('ADD')}
                  </Button>
                </Box>
                <p className="text-sm my-2">{t('TIP_SELECT_THE_INTERESTED_AREAS_AND_CLICK_ADD_BUTTON_ADDED_TO_THE_BELOW_AREA')}</p>
                <Box className={`grid grid-cols-4 sm:grid-cols-4 gap-2 sm:gap-4 border rounded-sm p-2 ${showErr && interestList.length == 0 ? 'border-red-500 text-red-500' : ''}`}>
                  {interestList.length > 0 ? interestList.map((area: string, i: number) => (
                    <Chip
                      color={"warning"}
                      key={`area_${i}`}
                      label={area}
                      variant="outlined"
                      sx={{ width: '100%' }}
                      onDelete={() => {
                        interestList.splice(i, 1);
                        setRefresh([]);
                      }}
                    />
                  )) : <p className="text-center col-span-4 sm:col-span-5">{t('NO_INTERESTED_AREA_SELECTED')}</p>}
                </Box> */}
              </Box>
              <>
                {/* <Box className="mt-8">
                <Box className="mb-2 border-l-8 border-themeYellow pl-4">{t('OTHER_SPECIAL_REQUESTS')}</Box>
                <TextField
                  value={specialRequest}
                  type="date"
                  onChange={(event: any) => setSpecialRequest(event.target.value)}
                  multiline
                  rows={4}
                  sx={{ width: '100%' }}
                />
              </Box> */}
              </>
              <Box sx={ITEM_PERFECT_INLINED} className="justify-center mt-8">
                <VmButton className="px-6 rounded-lg" onClick={onProcessTripInfo}>
                  <Box sx={ITEM_PERFECT_INLINED}>
                    <p className="mr-2">{t('PLAN_THIS_TRIP')}</p>
                    <ArrowCircleRightIcon />
                  </Box>
                </VmButton>
              </Box>
            </Box>
          </Box>
        </>}
    </>
  )
});

export default InititalTripStep;
